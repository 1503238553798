<template>
  <BooksExpensesList />
</template>

<script>
import BooksExpensesList from "./expenses-list/BooksExpensesList.vue";

export default {
  name: "BooksExpenses",
  components: {
    BooksExpensesList,
  },
};
</script>

<style></style>
