import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchExpenses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/books/expenses", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchExpense(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/books/expenses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addExpense(ctx, expenseData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/books/expenses", { expense: expenseData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
