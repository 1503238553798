<template>
  <b-sidebar
    id="add-new-expense-sidebar"
    :visible="isAddNewExpenseSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-expense-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Add Expense
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Reference -->
          <validation-provider
            #default="validationContext"
            name="Reference"
            rules="required"
          >
            <b-form-group
              label="Reference"
              label-for="reference"
            >
              <b-form-input
                id="reference"
                v-model="expenseData.reference"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Reference"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Client Name -->
          <validation-provider
            #default="validationContext"
            name="Client Name"
            rules="required"
          >
            <b-form-group
              label="Client Name"
              label-for="client-name"
            >
              <v-select
                id="client-name"
                v-model="expenseData.clientName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientName"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Vendor Name -->
          <validation-provider
            #default="validationContext"
            name="Vendor Name"
            rules="required"
          >
            <b-form-group
              label="Vendor Name"
              label-for="vendor-name"
            >
              <v-select
                id="vendor-name"
                v-model="expenseData.vendorName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="vendorName"
                :clearable="false"
                class="per-page-selector ml-0"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Amount -->
          <validation-provider
            #default="validationContext"
            name="Amount"
            rules="required"
          >
            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="expenseData.amount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Billed -->
          <validation-provider
            #default="validationContext"
            name="Billed"
          >
            <b-form-group
              label="Billed"
              label-for="billed"
            >
              <b-form-checkbox
                id="billed"
                v-model="expenseData.billed"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Expense Account -->
          <validation-provider
            #default="validationContext"
            name="Expense Account"
            rules="required"
          >
            <b-form-group
              label="Expense Account"
              label-for="expense-account"
            >
              <v-select
                id="expense-account"
                v-model="expenseData.expenseAccount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="expenseAccount"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Paid Through -->
          <validation-provider
            #default="validationContext"
            name="Paid Through"
            rules="required"
          >
            <b-form-group
              label="Paid Through"
              label-for="paid-through"
            >
              <v-select
                id="paid-through"
                v-model="expenseData.paidThrough"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paidThrough"
                :clearable="false"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="Date"
            rules="required"
          >
            <b-form-group
              label="Date"
              label-for="date"
            >
              <b-form-datepicker
                id="date"
                v-model="expenseData.date"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="per-page-selector ml-0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewExpenseSidebarActive",
    event: "update:is-add-new-expense-sidebar-active",
  },
  props: {
    isAddNewExpenseSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      expenseAccount: ["A", "B"],
      vendorName: ["Vendor 1", "Vendor 2"],
      clientName: ["Client 1", "Client 2"],
      paidThrough: ["Bank Account", "Paybal"],
      // billed: null,
    };
  },
  setup(props, { emit }) {
    const blankExpenseData = {
      reference: "",
      clientName: "",
      vendorName: "",
      paidThrough: "",
      // amount: "",
      // billed: "",
      expenseAccount: "",
      date: "",
    };

    const expenseData = ref(JSON.parse(JSON.stringify(blankExpenseData)));
    const resetexpenseData = () => {
      expenseData.value = JSON.parse(JSON.stringify(blankExpenseData));
    };
    const onSubmit = () => {
      store.dispatch("app-expense/addExpense", expenseData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-expense-sidebar-active", false);
      });
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetexpenseData);

    return {
      expenseData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-expense-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
